import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "title": "Edit Unscheduled Time", "after-close": _vm.afterModalClose }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isSubmitting }, on: { "click": function($event) {
        return handleSubmit(_vm.submitModal);
      } } }, [_vm._v(" Save ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "label": "Facility", "form-item": "", "reference": "line-performance.common.local-facilities", "source": "id", "source-label": _vm.parseFacilityLabel, "value": _vm.entity.facilityID, "disabled": true, "on-select-change": _vm.onFacilityChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "areaID", attrs: { "label": "Department", "placeholder": "Select Department", "form-item": "", "rules": "required", "data-source": _vm.listAreas, "source": "id", "source-label": "name", "span": 12, "value": _vm.entity.areaID, "on-select-change": _vm.onAreaChange } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "label": "Line", "form-item": "", "reference": "line-performance.common.local-production-lines", "custom-query": _vm.entity.areaID ? "areaId=" + _vm.entity.areaID : "", "source": "id", "source-label": "code", "value": _vm.entity.productionLineID }, on: { "change": function($event) {
      return _vm.storeValue("productionLineID", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { attrs: { "label": "Start Time", "show-time": "", "form-item": "", "format-date": "DD/MM/YYYY HH:mm", "rules": "required", "value": _vm.entity.startTime }, on: { "change": _vm.onStartTimeChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { attrs: { "label": "End Time", "show-time": "", "form-item": "", "format-date": "DD/MM/YYYY HH:mm", "rules": "required", "value": _vm.entity.endTime }, on: { "change": _vm.onEndTimeChange } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "form-item": "", "rules": "max:30", "label": "Reason", "placeholder": "Select Facility", "value": _vm.entity.reason }, on: { "change": function($event) {
      return _vm.storeValue("reason", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var EditUnscheduledDowntime_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "EditUnscheduledDowntime",
  inject: ["crud", "redirectRoute", "getListAreas"],
  data() {
    return {
      facilityId: "",
      areaId: "",
      visible: false,
      isSubmitting: false
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    },
    listAreas() {
      return this.getListAreas();
    }
  },
  created() {
    this.facilityId = this.entity.facilityID;
    this.areaId = this.entity.facilityID;
    this.storeValues(this.entity);
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    parseFacilityLabel(record) {
      if (record.description) {
        return record.name + " " + record.description;
      }
      return record.name;
    },
    onFacilityChange(value) {
      this.facilityId = value;
      this.storeValue("facilityID", value);
    },
    onAreaChange(value) {
      this.areaId = value;
      this.storeValue("areaID", value);
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      const fields = Object.keys(entity);
      fields.forEach((key) => this.storeValue(key, entity[key]));
    },
    submitModal() {
      this.isSubmitting = true;
      this.crud.submitEntity("replace").catch((err) => {
        console.log(err);
      }).finally(() => {
        this.isSubmitting = false;
        this.cancel();
      });
    },
    onEndTimeChange(value) {
      this.storeValue("endTime", this.$moment(value).utc().format("YYYY-MM-DD HH:mm"));
      this.storeValue("endTimeLocal", value);
    },
    onStartTimeChange(value) {
      this.storeValue("startTime", this.$moment(value).utc().format("YYYY-MM-DD HH:mm"));
      this.storeValue("startTimeLocal", value);
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.crud.fetchList();
      this.$router.push(this.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditUnscheduledDowntime = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "line-performance.common.local-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-areas", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-production-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.unscheduleddowntimes", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/unscheduled-time" } }, [_c("edit-unscheduled-downtime")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditUnscheduledDowntime
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
